<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('invoices')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('invoices')" :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <ValidationObserver ref="form">
                        <div class="row">
                            <div class="col-9">
                                <b-row>
                                    <div class="col-12 col-sm-6 col-xl-4">
                                        <ValidationProvider name="customer_group" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('student_group')">
                                                <multi-selectbox
                                                    :multiple="false"
                                                    :options="[{value:'TR', text:'TR'}, {value:'YU', text:'YU'}]"
                                                    :validate-error="errors[0]"
                                                    v-model="form.customer_group">
                                                </multi-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-xl-4">
                                        <ValidationProvider name="faculty_code" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('faculty')">
                                                <faculty-selectbox
                                                    ref="faculty"
                                                    :validate-error="errors[0]"
                                                    v-model="form.faculty_code">
                                                </faculty-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-xl-4">
                                            <ValidationProvider name="period_id" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('period')">
                                                    <payment-periods-selectbox
                                                        :semesters="semesters"
                                                        :validate-error="errors[0]"
                                                        v-model="form.period_id">
                                                    </payment-periods-selectbox>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                </b-row>
                            </div>
                            <div class="col-3">
                                <b-row>
                                    <b-col cols="12">
                                        <ValidationProvider name="student_number" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('student_numbers')">
                                                <lined-textarea v-model="form.student_number"
                                                                :nowrap="false"
                                                                :disabled="false"
                                                                :styles="{ height: '15em', resize: 'both' }"
                                                                :validateError="errors[0]">
                                                </lined-textarea>
                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <processing-button @click="send" label="get_report"
                                                   :processing="processing"></processing-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import qs from 'qs'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import CurrencySelectbox from "@/components/interactive-fields/CurrencySelectbox";
import InvoiceService from "@/services/InvoiceService";
import ProcessingButton from "@/components/elements/ProcessingButton";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import LinedTextarea from "@/components/elements/LinedTextarea";
import PaymentPlanService from "@/services/PaymentPlanService";

export default {
    components: {
        LinedTextarea,
        FacultySelectbox,
        ProcessingButton,
        CurrencySelectbox,
        PaymentPeriodsSelectbox,
        MultiSelectbox,
        AppLayout,
        ParameterSelectbox,
        ValidationProvider,
        ValidationObserver,
        Header,
        HeaderMobile
    },
    metaInfo() {
        return {
            title: this.$t('invoices')
        }
    },
    data() {
        return {
            form: {
            },
            processing: false,
            reportName: "",
            semesters: null
        }
    },
    methods: {
        async send() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.processing = true;
                let data = {...this.form};
                let oldValue=data.student_number;
                if(oldValue){
                    data.student_number=data.student_number.split(/\n/);
                }
                if(data.student_number && data.student_number.length>0){
                    let config = {params: data, responseType: 'arraybuffer'};

                    InvoiceService.report(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, true);
                    }).finally(() => {
                        this.processing = false;
                        this.form.student_number = oldValue;
                    });
                }
                else {
                    let config = {params: this.form};

                    InvoiceService.reportJob(config)
                        .then(response => {
                            this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form);
                        }).finally(() => {
                            this.processing = false;
                            this.form.student_number = oldValue;
                    });
                }
            }
        },
        callback(response) {
            let fileName = 'Fatura-'+this.form.customer_group+'.xlsx';
            if(this.form.faculty_code){
                var faculty = this.$refs.faculty.getItem(this.form.faculty_code);
                fileName = 'Fatura-'+faculty.text+'-'+this.form.customer_group+'.xlsx';
            }

            this._downloadFile(response,  fileName);
        }
    }
};
</script>

